var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order_no_details_box"},[_c('div',{staticClass:"order_heading"},[_c('h2',[_vm._v(_vm._s(_vm.$t("booking.order-no"))+" : "+_vm._s(_vm.orderNo))])]),(_vm.isDirectPayment)?_c('div',{staticClass:"order_details_contain"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"order_details_box"},[_c('ul',[_c('li',{staticStyle:{"font-size":"2rem"}},[_vm._m(0),_vm._v(" "+_vm._s(_vm.$t("booking.total-to-pay"))+" : "),_c('strong',[_vm._v("₪"+_vm._s(_vm.price))])])])])])]):_c('div',{staticClass:"order_details_contain"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"order_details_box"},[_c('ul',[_c('li',[_vm._m(1),_vm._v(_vm._s(_vm.$t("booking.status"))+" : "),_c('strong',[_vm._v(_vm._s(_vm.payer.status))])]),_c('li',[_vm._m(2),_vm._v(_vm._s(_vm.$t("booking.date"))+" : "),_c('strong',[_vm._v(_vm._s(_vm.today))])])])]),_c('div',{staticClass:"order_details_box"},[_c('ul',[_c('li',[_vm._m(3),_vm._v(_vm._s(_vm.$t("booking.name"))+" : "),_c('strong',[_vm._v(_vm._s(_vm.name))])]),_c('li',[_vm._m(4),_vm._v(_vm._s(_vm.$t("booking.phone"))+" : "),_c('strong',[_vm._v(_vm._s(_vm.mobile))])]),_c('li',[_vm._m(5),_vm._v(_vm._s(_vm.$t("booking.email"))+" : "),_c('strong',[_vm._v(_vm._s(_vm.email))])])])]),_c('div',{staticClass:"order_details_box"},[_c('ul',[_c('li',[_vm._m(6),_vm._v(_vm._s(_vm.$t("booking.total-to-pay"))+" : "),_c('strong',[_vm._v("₪"+_vm._s(_vm.price))])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon1.png","alt":"after-booking-icon","title":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon5.png","alt":"after-booking-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon6.png","alt":"after-booking-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon2.png","alt":"after-booking-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon3.png","alt":"after-booking-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon4.png","alt":"after-booking-icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon"},[_c('img',{attrs:{"src":"/assets/img/after-booking-icon1.png","title":"","alt":"after-booking-icon"}})])
}]

export { render, staticRenderFns }